import { ensureElementInDom } from '@erp/lib/dom_util';

class ErpFormComponents {
  private onChangeMultipleCheckbox(wrapper: HTMLDivElement, multipleCheckboxDropdownText: HTMLDivElement, defaultFormText: string) {
    const checkedText: string[] = [];
    wrapper.querySelectorAll<HTMLInputElement>('.form-check-input:checked').forEach((checkedInput) => {
      const checkedLabel = checkedInput.closest<HTMLLabelElement>('.dropdown-item');
      ensureElementInDom(checkedLabel, 'checkedLabel');
      checkedText.push(checkedLabel.innerText);
    });
    multipleCheckboxDropdownText.innerText = checkedText.length > 0 ? checkedText.join(',') : defaultFormText;
  }

  run() {
    document.querySelectorAll<HTMLDivElement>('.multiple-checkbox-wrapper').forEach((wrapper) => {
      const defaultFormText = wrapper.dataset.defaultFormText ?? '選択';
      const multipleCheckboxDropdownText = wrapper.querySelector<HTMLDivElement>('.multiple-checkbox-dropdown-text');
      ensureElementInDom(multipleCheckboxDropdownText, 'multipleCheckboxDropdownText');
      wrapper.querySelectorAll<HTMLInputElement>('.form-check-input').forEach((input) => {
        input.addEventListener('change', () => { this.onChangeMultipleCheckbox(wrapper, multipleCheckboxDropdownText, defaultFormText); });
      });
    });
  }
}

const form = new ErpFormComponents();
form.run();
